export default {
    id: "caratviewboot",
    eventName: "addPanorama",
    
    configPath : "/config/",
    stylePath: "/css/",

    appScript: "app.module.js",
    styleFile : "style.css",

    "alias": {
        "guid": ["load"],
        "serverUrl" : ["serverurl"]
    }
};